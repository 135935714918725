import { useMediaQuery } from '@mui/material';
import { NavLink } from 'react-router-dom';
import styled from '@emotion/styled/macro';

import MobileMenu from './MobileMenu';
import PlayerSearch from './PlayerSearch';
import UserBar from './Userbar';
import useCheckSportBudgetPermission from '../../hooks/useCheckSportBudgetPermission';
import { routes } from 'app/Router';
import { ReactComponent as StarSVG } from 'assets/star.svg';
import useUserStore from 'entities/User.model';
import { OrgRolePermissionActionEnum } from 'shared/api/user';

const Header = () => {
  const { currentOrgUser, record, checkPermission } = useUserStore();
  const smallScreen = useMediaQuery('(max-width: 1100px)');

  const { isSportBudgetPageAllowed } = useCheckSportBudgetPermission();
  const permission =
    currentOrgUser?.organization.id === '1' &&
    (currentOrgUser.orgRole.telegramRequired ? record.user.hasTelegram : true);

  const renderLink = (to: string, label: string) => <LinkStyled to={to}>{label}</LinkStyled>;

  return (
    <HeaderStyled>
      <Navigation>
        <ProjectTitle>
          <Logo />
          <Divider />
          <Title>Портал</Title>
        </ProjectTitle>
        {permission && (
          <Links>
            {renderLink(routes.club, 'Команда')}
            {renderLink(routes.trainings, 'Тренировки')}
            {renderLink(routes.matches, 'Матчи')}
            {renderLink(routes.measurements, 'Данные')}
            {renderLink(routes.missingPlayer, 'Отсутствия')}
            {checkPermission(OrgRolePermissionActionEnum.ScoutingResearchingShow) &&
              renderLink(routes.scouting, 'Селекция')}
            {isSportBudgetPageAllowed && renderLink(routes.sportBudget, 'Спортивный бюджет')}
          </Links>
        )}
      </Navigation>
      <Actions>
        {permission && <PlayerSearch />}
        {smallScreen ? <MobileMenu /> : <UserBar />}
      </Actions>
    </HeaderStyled>
  );
};

export default Header;

const HeaderStyled = styled.header`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 0 var(--space-lg);
  padding-top: 4px;
  background-color: var(--slate-5);

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 4px;
    background-image: linear-gradient(to right, var(--error) 50%, var(--primary) 50%);
    background-size: 20% 100%;
    background-repeat: repeat-x;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 768px) {
    padding-left: var(--space-md);
    padding-right: var(--space-md);

    &:before {
      background-size: 50% 100%;
    }
  }

  @media print {
    display: none;
  }
`;

const Navigation = styled.div`
  display: flex;
  align-items: center;
  gap: var(--space-xl);
`;

const ProjectTitle = styled.div`
  display: flex;
  align-items: center;
  gap: var(--space-lg);
`;

const Logo = styled(StarSVG)`
  height: 30px;
`;

const Divider = styled.div`
  flex: 0 0 auto;
  width: 1px;
  height: 30px;
  background-color: var(--neutral-border-focus);

  @media (max-width: 768px) {
    display: none;
  }
`;

const Title = styled.h1`
  margin: 0;
  font-size: var(--fz-lg);
  font-weight: 500;

  @media (max-width: 768px) {
    display: none;
  }
`;

const Links = styled.nav`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-top: 2px;

  @media (max-width: 1100px) {
    display: none;
  }
`;

const LinkStyled = styled(NavLink)`
  position: relative;

  &.active {
    color: var(--primary);
  }

  &:hover {
    text-decoration: underline;
  }

  &:before {
    content: '/';
    position: absolute;
    top: 50%;
    left: -1rem;
    transform: translate(-50%, -50%);
    color: var(--neutral-border);
    pointer-events: none;
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: var(--space-md);
`;
