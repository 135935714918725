import { useEffect, useRef } from 'react';

import useUserStore from 'entities/User.model';

interface Props {
  onLogin?: (user?: any) => void;
  onError?: (error?: { code: string; message: string }) => void;
}

const TelegramLoginButton = ({ onLogin, onError }: Props) => {
  const { signInWithTelegram } = useUserStore();
  const instanceRef = useRef<HTMLDivElement | null>(null);

  const handleTelegramAuth = async (user: any) => {
    try {
      await signInWithTelegram({
        id: user.id,
        authDate: user.auth_date,
        hash: user.hash,
        firstName: user.first_name,
        lastName: user.last_name,
        username: user.username,
        photoUrl: user.photo_url,
        scope: 'portal',
      });
      onLogin?.(user);
    } catch (error) {
      onError?.(error);
    }
  };

  useEffect(() => {
    window.TelegramLoginWidget = {
      onTelegramAuth: (user: any) => handleTelegramAuth(user),
    };

    const script = document.createElement('script');
    script.src = `https://telegram.org/js/telegram-widget.js?22`;
    script.setAttribute('data-telegram-login', 'RbPortal_bot');
    script.setAttribute('data-size', 'large');
    script.setAttribute('data-request-access', 'write');
    script.setAttribute('data-lang', 'ru');
    script.setAttribute('data-onauth', 'TelegramLoginWidget.onTelegramAuth(user)');
    script.async = true;
    instanceRef.current?.appendChild(script);
  }, []);

  return <div ref={instanceRef} />;
};

export default TelegramLoginButton;
